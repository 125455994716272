import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var treeHole = function treeHole() {
  return import("./common/treeHole");
};

var comment = function comment() {
  return import("./comment/comment");
};

var myFooter = function myFooter() {
  return import("./common/myFooter");
};

var photo = function photo() {
  return import("./common/photo");
};

var proTag = function proTag() {
  return import("./common/proTag");
};

var proButton = function proButton() {
  return import("./common/proButton");
};

var uploadPicture = function uploadPicture() {
  return import("./common/uploadPicture");
};

export default {
  components: {
    comment: comment,
    photo: photo,
    treeHole: treeHole,
    myFooter: myFooter,
    proTag: proTag,
    proButton: proButton,
    uploadPicture: uploadPicture
  },
  data: function data() {
    return {
      userLove: {
        bgCover: "",
        manCover: "",
        womanCover: "",
        manName: "",
        womanName: "",
        countdownTitle: "",
        countdownTime: "",
        timing: "",
        familyInfo: ""
      },
      loveDialogVisible: false,
      addPictureDialog: false,
      pictureType: "",
      adminLove: {},
      love: {
        bgCover: "",
        manCover: "",
        womanCover: "",
        manName: "",
        womanName: "",
        countdownTitle: "",
        countdownTime: "",
        timing: ""
      },
      weiYanPagination: {
        current: 1,
        size: 10,
        total: 0,
        userId: this.$constant.userId
      },
      photoPagination: {
        current: 1,
        size: 10,
        total: 0,
        resourceType: "lovePhoto",
        classify: ""
      },
      treeHoleList: [],
      photoTitleList: [],
      photoList: [],
      randomFamily: [],
      card: null,
      countdownChange: "",
      timing: {
        year: 0,
        month: 0,
        day: 0,
        hour: 0,
        minute: 0,
        second: 0
      }
    };
  },
  computed: {},
  watch: {},
  created: function created() {
    this.getAdminFamily();
    this.card = 1;
    this.getWeiYan();
  },
  mounted: function mounted() {},
  methods: {
    openPicture: function openPicture(type) {
      this.pictureType = type;
      this.addPictureDialog = true;
    },
    addPicture: function addPicture(res) {
      if (this.pictureType === "bgCover") {
        this.userLove.bgCover = res;
      } else if (this.pictureType === "manCover") {
        this.userLove.manCover = res;
      } else if (this.pictureType === "womanCover") {
        this.userLove.womanCover = res;
      }

      this.pictureType = "";
      this.addPictureDialog = false;
    },
    submitLove: function submitLove() {
      var _this = this;

      if (this.userLove.bgCover.trim() === "") {
        this.$message({
          message: "你还没设置背景封面呢~",
          type: "warning"
        });
        return;
      }

      if (this.userLove.manCover.trim() === "") {
        this.$message({
          message: "你还没设置男生头像呢~",
          type: "warning"
        });
        return;
      }

      if (this.userLove.womanCover.trim() === "") {
        this.$message({
          message: "你还没设置女生头像呢~",
          type: "warning"
        });
        return;
      }

      if (this.userLove.manName.trim() === "") {
        this.$message({
          message: "你还没写男生昵称呢~",
          type: "warning"
        });
        return;
      }

      if (this.userLove.womanName.trim() === "") {
        this.$message({
          message: "你还没写女生昵称呢~",
          type: "warning"
        });
        return;
      }

      if (this.userLove.timing.trim() === "") {
        this.$message({
          message: "你还没设置计时时间呢~",
          type: "warning"
        });
        return;
      }

      this.$http.post(this.$constant.baseURL + "/family/saveFamily", this.userLove).then(function (res) {
        _this.$message({
          type: 'success',
          message: '提交成功，待管理员审核！'
        });

        _this.userLove = {};
        _this.loveDialogVisible = false;
      }).catch(function (error) {
        _this.$message({
          message: error.message,
          type: "error"
        });
      });
    },
    addFamily: function addFamily() {
      var _this2 = this;

      if (this.$common.isEmpty(this.$store.state.currentUser)) {
        this.$message({
          message: "请先登录！",
          type: "error"
        });
        return;
      }

      this.$http.get(this.$constant.baseURL + "/family/getFamily").then(function (res) {
        if (!_this2.$common.isEmpty(res.data)) {
          _this2.userLove = res.data;
        }
      }).catch(function (error) {
        _this2.$message({
          message: error.message,
          type: "error"
        });
      });
      this.loveDialogVisible = true;
    },
    changeFamily: function changeFamily(family) {
      this.love = family;
    },
    getPhotoTitles: function getPhotoTitles() {
      var _this3 = this;

      this.$http.get(this.$constant.baseURL + "/webInfo/listAdminLovePhoto").then(function (res) {
        if (!_this3.$common.isEmpty(res.data)) {
          _this3.photoTitleList = res.data;
          _this3.photoPagination = {
            current: 1,
            size: 10,
            total: 0,
            resourceType: "lovePhoto",
            classify: _this3.photoTitleList[0].classify
          };

          _this3.changePhoto();
        }
      }).catch(function (error) {
        _this3.$message({
          message: error.message,
          type: "error"
        });
      });
    },
    getAdminFamily: function getAdminFamily() {
      var _this4 = this;

      this.$http.get(this.$constant.baseURL + "/family/getAdminFamily").then(function (res) {
        if (!_this4.$common.isEmpty(res.data)) {
          _this4.love = res.data;
          _this4.adminLove = res.data;

          _this4.getLove();

          _this4.countdown();

          setInterval(function () {
            _this4.getLove();

            _this4.countdown();
          }, 1000);
        }
      }).catch(function (error) {
        _this4.$message({
          message: error.message,
          type: "error"
        });
      });
    },
    getRandomFamily: function getRandomFamily() {
      var _this5 = this;

      this.$http.get(this.$constant.baseURL + "/family/listRandomFamily").then(function (res) {
        if (!_this5.$common.isEmpty(res.data)) {
          _this5.randomFamily = res.data;
        }
      }).catch(function (error) {
        _this5.$message({
          message: error.message,
          type: "error"
        });
      });
    },
    changePhotoTitle: function changePhotoTitle(classify) {
      if (classify !== this.photoPagination.classify) {
        this.photoPagination = {
          current: 1,
          size: 10,
          total: 0,
          resourceType: "lovePhoto",
          classify: classify
        };
        this.photoList = [];
        this.changePhoto();
      }
    },
    pagePhotos: function pagePhotos() {
      this.photoPagination.current = this.photoPagination.current + 1;
      this.changePhoto();
    },
    changePhoto: function changePhoto() {
      var _this6 = this;

      this.$http.post(this.$constant.baseURL + "/webInfo/listResourcePath", this.photoPagination).then(function (res) {
        if (!_this6.$common.isEmpty(res.data)) {
          _this6.photoList = _this6.photoList.concat(res.data.records);
          _this6.photoPagination.total = res.data.total;
        }
      }).catch(function (error) {
        _this6.$message({
          message: error.message,
          type: "error"
        });
      });
    },
    changeCard: function changeCard(card) {
      if (card !== 4 || this.card !== card) {
        this.card = card;
      } else {
        card = 1;
        this.card = 1;
        this.love = this.adminLove;
      }

      if (card === 1) {
        if (this.$common.isEmpty(this.treeHoleList)) {
          this.getWeiYan();
        }
      } else if (card === 2) {
        if (this.$common.isEmpty(this.photoTitleList)) {
          this.getPhotoTitles();
        }
      } else if (card === 4) {
        if (this.$common.isEmpty(this.randomFamily)) {
          this.getRandomFamily();
        }
      }
    },
    getLove: function getLove() {
      if (this.$common.isEmpty(this.love.timing)) {
        return;
      }

      var diff = this.$common.timeDiff(this.love.timing);
      this.timing.year = diff.diffYear;
      this.timing.month = diff.diffMonth;
      this.timing.day = diff.diffDay;
      this.timing.hour = diff.diffHour;
      this.timing.minute = diff.diffMinute;
      this.timing.second = diff.diffSecond;
    },
    countdown: function countdown() {
      if (this.$common.isEmpty(this.love.countdownTime)) {
        return;
      }

      var countdown = this.$common.countdown(this.love.countdownTime);
      this.countdownChange = countdown.d + "天" + countdown.h + "时" + countdown.m + "分" + countdown.s + "秒";
    },
    launch: function launch() {
      if (this.weiYanPagination.total !== this.treeHoleList.length) {
        this.weiYanPagination.current = this.weiYanPagination.current + 1;
        this.getWeiYan();
      } else {
        this.$message({
          message: "~~到底啦~~",
          type: "warning"
        });
      }
    },
    getWeiYan: function getWeiYan() {
      var _this7 = this;

      this.$http.post(this.$constant.baseURL + "/weiYan/listWeiYan", this.weiYanPagination).then(function (res) {
        if (!_this7.$common.isEmpty(res.data)) {
          res.data.records.forEach(function (c) {
            c.content = c.content.replace(/\n{2,}/g, '<div style="height: 12px"></div>');
            c.content = c.content.replace(/\n/g, '<br/>');
            c.content = _this7.$common.faceReg(c.content);
            c.content = _this7.$common.pictureReg(c.content);
          });
          _this7.treeHoleList = _this7.treeHoleList.concat(res.data.records);
          _this7.weiYanPagination.total = res.data.total;
        }
      }).catch(function (error) {
        _this7.$message({
          message: error.message,
          type: "error"
        });
      });
    },
    deleteTreeHole: function deleteTreeHole(id) {
      var _this8 = this;

      if (this.$common.isEmpty(this.$store.state.currentUser)) {
        this.$message({
          message: "请先登录！",
          type: "error"
        });
        return;
      }

      this.$confirm('确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success',
        center: true
      }).then(function () {
        _this8.$http.get(_this8.$constant.baseURL + "/weiYan/deleteWeiYan", {
          id: id
        }).then(function (res) {
          _this8.$message({
            type: 'success',
            message: '删除成功!'
          });

          _this8.weiYanPagination.current = 1;

          _this8.getWeiYan();
        }).catch(function (error) {
          _this8.$message({
            message: error.message,
            type: "error"
          });
        });
      }).catch(function () {
        _this8.$message({
          type: 'success',
          message: '已取消删除!'
        });
      });
    }
  }
};